import React, { useCallback, useRef, useState } from 'react';
import { Layer, Map, Marker, NavigationControl, Popup, Source } from 'react-map-gl';

import nodataIcon from '../../../images/nodata_icon_v1.svg';
import warIcon from '../../../images/war_icon_v1.svg';
import config from '../../config';
import { Color } from '../../theme';

import { ReactComponent as MarkerIcon } from './images/marker.svg';

import styles from './WorldColoredMap.module.scss';

const COUNTRIES_SET = 'countries-set';
const REGIONS_SET = 'regions-set';
const LEVELS_COUNT = 11;
const CONFLICT_AREA_INDEX = -1;
const TEXT_LABEL_LAYER = 'place-town-village-hamlet-label';
const NA = 'N/A';

const ZOOM_MIN = 3.3;
const ZOOM_DETAIL = 4.5;
const ZOOM_MAX = 7.9;

const {
	accessTokenRatingMap,
	styleRatingMap,
	tilesetCountryLevel,
	tilesetRegionLevel,
	layerCountryLevel,
	layerRegionLevel,
} = config.mapbox;

const vicriColors = {
	0: Color.heatmap0,
	1: Color.heatmap1,
	2: Color.heatmap2,
	3: Color.heatmap3,
	4: Color.heatmap4,
	5: Color.heatmap5,
	6: Color.heatmap6,
	7: Color.heatmap7,
	8: Color.heatmap8,
	9: Color.heatmap9,
	10: Color.heatmap10,
	11: Color.heatmap11,
	'-1': Color.heatmap12,
};

const getVicriRating = (code, data) => {
	const found = Object.entries(data).find(([_, locations]) => locations.includes(code));
	return found ? found[0] : null;
};

function generateLayerIdList(numItems, locationType) {
	const interactiveLayerIds = [];
	for (let i = -1; i <= numItems; i += 1) {
		interactiveLayerIds.push(`layer-${locationType}-${i}`);
	}
	return interactiveLayerIds;
}

const interactiveLayerIdsCountry = generateLayerIdList(LEVELS_COUNT, 'country');
const interactiveLayerIdsRegion = generateLayerIdList(LEVELS_COUNT, 'region');

const dataLayerCountriesBorder = {
	id: 'layer-country-border',
	type: 'line',
	source: COUNTRIES_SET,
	'source-layer': layerCountryLevel,
	paint: {
		'line-color': '#777',
		'line-opacity': 0.7,
		'line-width': 1,
	},
};

function LocationPopup({ popupData }) {
	const isConflictZone = popupData.vicriRating === CONFLICT_AREA_INDEX;
	const isPoorData = !popupData.vicriRating;

	return (
		<div className={styles.popup}>
			<h4 className={styles.title}>Details</h4>
			<p>Location: {popupData.vicriLocation || NA}</p>
			{!isConflictZone && !isPoorData && (
				<p className={styles.rating}>ViCRI Rating: {popupData.vicriRating || NA}</p>
			)}
			<div className={styles.icon}>
				{isConflictZone && <img src={warIcon} alt="war icon" />}
				{isPoorData && <img src={nodataIcon} alt="nodata icon" />}
			</div>
		</div>
	);
}

function WorldColoredMap({
	crimeIndexGeography,
	destinationCoordinates,
	defaultZoom = ZOOM_MAX,
	showPopup = false,
	showMarker = false,
	projection = 'mercator',
	disableMoving = true,
}) {
	const { lat, lng } = destinationCoordinates;
	const { national, regional } = crimeIndexGeography;

	const [viewport, setViewport] = useState({
		latitude: lat,
		longitude: lng,
		zoom: ZOOM_MIN,
	});

	const [popupInfo, setPopupInfo] = useState(null);
	const mapRef = useRef(null);

	const handleMapLoad = () => {
		if (mapRef.current) {
			mapRef.current.flyTo({
				center: [lng, lat],
				zoom: defaultZoom,
				duration: 2000,
				essential: true,
			});
		}
	};

	const onPopup = useCallback((event) => {
		const countryFeature = event.features.find((feature) => feature.layer.source === COUNTRIES_SET);
		const regionFeature = event.features.find((feature) => feature.layer.source === REGIONS_SET);

		let vicriRating = null;
		let vicriLocation = null;

		if (regionFeature) {
			const regionCode = regionFeature.properties.gid;
			vicriRating = +getVicriRating(regionCode, regional);
			vicriLocation = regionFeature.properties.name;
		} else if (countryFeature) {
			const countryCode = countryFeature.properties.gid;
			vicriRating = +getVicriRating(countryCode, national);
			vicriLocation = countryFeature.properties.name;
		}
		if (vicriRating || vicriLocation) {
			setPopupInfo({
				longitude: event.lngLat.lng,
				latitude: event.lngLat.lat,
				vicriRating,
				vicriLocation,
			});
		}
	}, []);

	// TODO: need to remove after testing
	// console.log('### zoom:', viewport.zoom);

	return (
		<div className={styles['map-container']}>
			<Map
				dragPan={!disableMoving}
				ref={mapRef}
				projection={projection}
				minZoom={ZOOM_MIN}
				maxZoom={ZOOM_MAX}
				width="100%"
				height="100%"
				mapStyle={styleRatingMap}
				mapboxAccessToken={accessTokenRatingMap}
				{...viewport}
				onLoad={handleMapLoad}
				onZoom={(evt) => {
					const currentViewport = {
						...evt.viewport,
						// TODO: need to check is it a cause of the zoom lag?
						zoom: evt.viewState.zoom,
					};
					if (disableMoving) {
						currentViewport.latitude = lat;
						currentViewport.longitude = lng;
					}
					return setViewport(currentViewport);
				}}
				onClick={!popupInfo && onPopup}
				interactiveLayerIds={[...interactiveLayerIdsCountry, ...interactiveLayerIdsRegion]}
				attributionControl={false}
			>
				{showMarker && (
					<Marker longitude={lng} latitude={lat} anchor="bottom" offset={[0, -2]}>
						<MarkerIcon />
					</Marker>
				)}
				<Source id={COUNTRIES_SET} type="vector" url={tilesetCountryLevel}>
					{Object.entries(vicriColors).map(([vicri, color]) => (
						<Layer
							key={vicri}
							id={'layer-country-'.concat(vicri)}
							type="fill"
							source-layer={layerCountryLevel}
							filter={['in', 'gid', ...national[vicri]]}
							paint={{
								'fill-color': color,
								'fill-opacity': 1,
							}}
							beforeId={TEXT_LABEL_LAYER}
						/>
					))}
					<Layer {...dataLayerCountriesBorder} />
				</Source>
				<Source id={REGIONS_SET} type="vector" url={tilesetRegionLevel}>
					{Object.entries(vicriColors).map(([vicri, color]) => (
						<React.Fragment key={vicri}>
							<Layer
								id={'layer-region-'.concat(vicri)}
								type="fill"
								source={REGIONS_SET}
								source-layer={layerRegionLevel}
								filter={['in', 'gid', ...regional[vicri]]}
								paint={{
									'fill-color': color,
									'fill-opacity': 1,
								}}
								minzoom={ZOOM_DETAIL}
								maxzoom={ZOOM_MAX + 1}
								beforeId={TEXT_LABEL_LAYER}
							/>
							<Layer
								id={'layer-region-borders'.concat(vicri)}
								type="line"
								source={REGIONS_SET}
								source-layer={layerRegionLevel}
								filter={['in', 'gid', ...regional[vicri]]}
								paint={{
									'line-color': '#777',
									'line-opacity': 0.5,
									'line-width': 0.3,
								}}
								minzoom={ZOOM_DETAIL}
								maxzoom={ZOOM_MAX + 1}
							/>
						</React.Fragment>
					))}
				</Source>

				{showPopup && popupInfo && (
					<Popup
						tipSize={5}
						anchor="bottom"
						longitude={popupInfo.longitude}
						latitude={popupInfo.latitude}
						closeOnClick
						onClose={() => setPopupInfo(null)}
					>
						<LocationPopup popupData={popupInfo} />
					</Popup>
				)}
				<NavigationControl showCompass={false} visualizePitch={false} />
			</Map>
		</div>
	);
}

export default WorldColoredMap;
